<template>
  <div class="home">
    <LogoAtom/>
    <InitialViewManager msg="Welcome to KYC Orchestrator"/>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import LogoAtom from "@/components/atoms/AppLogoAtom.vue";
import InitialViewManager from "@/views/manager/InitialViewManager.vue";

@Options({
  components: {
    InitialViewManager,
    LogoAtom
  },
})
export default class HomeView extends Vue {
}
</script>
