<template>
  <div class="widget-container">
    <label :for="widgetConfig.id" class="input-label">{{ widgetConfig.properties.label }}</label>
    <input
      :id="widgetConfig.id"
      type="text"
      v-model="inputValue"
      @input="validateInput"
      class="text-input"
    />
    <div v-if="errorMessage" class="widget-error-message">{{ errorMessage }}</div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType, ref, watch} from "vue";

type Properties = {
  label: string;
}

type ValidationRule = {
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
}

type Validation = {
  rules: ValidationRule[];
  message: string;
}

type Field = {
  id: string;
  type: string;
  value: string;
  properties: Properties;
  validation: Validation[];
  submitted: boolean;
}

export default defineComponent({
  name: "TextWidget",
  props: {
    widgetConfig: {
      type: Object as PropType<Field>,
      required: true,
    },
  },
  setup(props) {
    const inputValue = ref(props.widgetConfig.value || "");
    const errorMessage = ref("");

    const validateInput = () => {
      errorMessage.value = "";

      if (!props.widgetConfig.validation) {
        return;
      }

      for (const validation of props.widgetConfig.validation) {
        for (const rule of validation.rules) {
          if (rule.required && !inputValue.value) {
            errorMessage.value = validation.message;
            break;
          }
          if (rule.minLength && inputValue.value.length < rule.minLength) {
            errorMessage.value = validation.message;
            break;
          }
          if (rule.maxLength && inputValue.value.length > rule.maxLength) {
            errorMessage.value = validation.message;
            break;
          }
          if (rule.pattern && !new RegExp(rule.pattern).test(inputValue.value)) {
            errorMessage.value = validation.message;
            break;
          }
        }
        if (errorMessage.value) break;
      }
      props.widgetConfig.submitted = !errorMessage.value;
      props.widgetConfig.value = inputValue.value;
    };

    watch(inputValue, validateInput);

    validateInput();

    return {
      inputValue,
      errorMessage,
      validateInput,
    };
  },
});
</script>

<style scoped>

.input-label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.text-input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border-radius: 3px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}
</style>
