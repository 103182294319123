<template>
  <nav class="navigation-widget">
    <div class="navigation-buttons">
      <button v-if="navigationConfig?.allowBack" class="back-button" @click="goBack" title="Back">
        <i class="pi pi-chevron-left"></i>
      </button>
      <div class="navigation-title">{{ navigationConfig?.title }}</div>
      <button v-if="navigationConfig?.allowHelp" class="help-button" title="Help">
        <i class="pi pi-question-circle"></i>
      </button>
    </div>
  </nav>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component";
import {JourneyNavigation} from "@/service/server/model/response/JourneyNavigation";
import router from "@/router";

@Options({
  name: 'NavigationWidget',
  props: {
    navigationConfig: {
      type: Object as () => JourneyNavigation,
      required: true
    }
  },
})
export default class NavigationWidget extends Vue {
  navigationConfig!: JourneyNavigation;

  goBack() {
    router.back();
  }
}
</script>

<style scoped>
.navigation-widget {
  padding: 20px;
  border-bottom: 1px solid #ccc;
}

.navigation-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navigation-title {
  font-size: 1.2em;
  color: #333;
  font-weight: bold;
}

button {
  background: none;
  border: none;
  color: #007bff;
  font-size: 1em;
  cursor: pointer;
  transition: color 0.3s ease;
}

button:hover {
  color: #0056b3;
}

.back-button {
  font-size: 1em;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.back-button i {
  margin-right: 5px;
}

.help-button {
  font-size: 1em;
}

hr {
  border: none;
  border-top: 1px solid #ccc;
  margin-top: 10px;
}
</style>
