/**
 * Server response for a journey
 */
export class JourneyResponse implements JourneyResponseDef {
  metadata: JourneyMetadataDef;
  navigation: JourneyNavigationDef;
  screen: JourneyContentDef;
  token: string;

  constructor(response: JourneyResponseDef) {
    this.metadata = response.metadata;
    this.navigation = response.navigation;
    this.screen = response.screen;
    this.token = response.token;
  }
}
