import apiService from "@/service/server/EndpointService";
import {AxiosResponse} from "axios";

class JourneyIdWrapperDto {
  journeyId: string;

  constructor(journeyId: string) {
    this.journeyId = journeyId;
  }

}

class OtpApiService {
  otpUrl: string = 'otp/resend';

  async resendOtp(journeyId: any): Promise<AxiosResponse> {
    console.log('resending OTP');
    return apiService.post(this.otpUrl, new JourneyIdWrapperDto(journeyId));
  }
}

export default new OtpApiService();
