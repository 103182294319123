<template>
  <div class="completed-view">
    <div class="animation-container">
      <div class="rocket">
        <div class="rocket-body">
          <div class="body"></div>
          <div class="fin fin-left"></div>
          <div class="fin fin-right"></div>
          <div class="window"></div>
        </div>
        <div class="exhaust-flame"></div>
        <ul class="exhaust-fumes">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <ul class="star">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </div>
    <h1 class="message">You did it, journey is completed!</h1>
  </div>
</template>


<script lang="ts">


import {Options, Vue} from "vue-class-component";

@Options({
  name: 'CompletedView'
})
export default class CompletedView extends Vue {

}
</script>
