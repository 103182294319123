import {createApp} from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import '@/assets/main.css';
import '@/assets/widget-style.css';
import 'vue-pdf-embed/dist/style/index.css'
import 'primeicons/primeicons.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-multiselect/dist/vue-multiselect.css';
import '@vuepic/vue-datepicker/dist/main.css';
import VueSweetalert2 from 'vue-sweetalert2';


createApp(App)
  .use(router)
  .use(VueSweetalert2)
  .mount('#app')
