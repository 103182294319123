<template>
  <div class="widget-container">
    <div class="area-code-container">
      <Multiselect v-if="widgetConfig.properties.showAreaCode" v-model="areaCode"
                   :options="areaCodes" class="area-code-dropdown" placeholder="+XX"/>

      <input type="text" v-model="phoneNumber" class="phone-number-input" @input="validatePhoneNumber"
             :placeholder="widgetConfig.properties.showAreaCode ? 'Phone Number' : 'Area Code + Phone Number'"/>
    </div>
    <div v-if="showPhoneError" class="widget-error-message">Invalid phone number</div>
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, PropType, ref, watch} from "vue";
import journeyApiService from "@/service/server/api/JourneyApiService";
import Multiselect from "vue-multiselect";

type Properties = {
  showAreaCode: boolean;
  source: {
    url: string;
  };
}

type Field = {
  id: string;
  type: string;
  submitted: boolean;
  properties: Properties;
  value: {
    areaCode: string;
    number: string;
  };
}

export default defineComponent({
  name: "PhoneNumberWidget",
  props: {
    widgetConfig: {
      type: Object as PropType<Field>,
      required: true,
    },
  },
  components: {
    Multiselect
  },
  setup(props) {
    const areaCode = ref(props.widgetConfig.value.areaCode || "");
    const phoneNumber = ref(props.widgetConfig.value.number || "");
    const showPhoneError = ref(false);
    const areaCodes = ref<string[]>([]);

    const validatePhoneNumber = () => {
      const regex = /^\d+$/;
      showPhoneError.value = !regex.test(phoneNumber.value);
      props.widgetConfig.submitted = !showPhoneError.value;
      props.widgetConfig.value = {areaCode: areaCode.value, number: phoneNumber.value};
    };

    watch(phoneNumber, validatePhoneNumber);

    onMounted(async () => {
      try {
        const response = await journeyApiService.getJourneyContentsCollections(
          localStorage.getItem("journeyId"),
          props.widgetConfig.properties.source.url.lastIndexOf("/") > -1
            ? props.widgetConfig.properties.source.url.substring(props.widgetConfig.properties.source.url.lastIndexOf("/") + 1)
            : props.widgetConfig.properties.source.url
        );
        console.log("Area codes", response.data);
        areaCodes.value = response.data.map((country: any) => country.areaCode);
      } catch (error) {
        console.error("Error loading area codes", error);
      }
    });

    return {
      areaCode,
      phoneNumber,
      showPhoneError,
      areaCodes,
      validatePhoneNumber,
    };
  },
});
</script>

<style scoped>

.area-code-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.area-code-dropdown {
  margin: 2%;
  flex: 1;
  font-size: 14px;
}

.phone-number-input {
  flex: 3;
  padding: 8px;
  font-size: 14px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

</style>
