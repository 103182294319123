import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "widget-container" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "widget-error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.widgetConfig.id
    }, _toDisplayString(_ctx.widgetConfig.properties.label), 9, _hoisted_2),
    _createVNode(_component_Multiselect, {
      modelValue: _ctx.selectedValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedValue) = $event)),
      options: _ctx.options,
      label: "name",
      "track-by": "id",
      placeholder: "Select an item",
      class: "dropdown-select",
      onInput: _ctx.validateSelection
    }, null, 8, ["modelValue", "options", "onInput"]),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true)
  ]))
}