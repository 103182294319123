import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "journey-view" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JourneyViewManager = _resolveComponent("JourneyViewManager")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.journeyConfig)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_JourneyViewManager, { journeyConfig: _ctx.journeyConfig }, null, 8, ["journeyConfig"])
        ]))
      : _createCommentVNode("", true)
  ]))
}