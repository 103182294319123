<template>
  <div class="otp-widget">
    <label for="otp-input">Enter OTP:</label>
    <div class="otp-inputs">
      <input
        v-for="(digit, index) in otpDigits"
        :key="index"
        type="text"
        maxlength="1"
        v-model="otpDigits[index]"
        @input="onInput(index, $event)"
        class="otp-digit-input"
        ref="otpInputs"
      />
    </div>
    <div v-if="showOtpError" class="widget-error-message">Invalid OTP</div>
    <div class="resend-container">
      <p>This code is valid for 3 minutes.</p>
      <button @click="resendOtp">Resend OTP</button>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType, ref, watch} from "vue";
import otpApiService from "@/service/server/api/OtpApiService";
import Swal from "sweetalert2";

type Properties = {
  length: number;
}

type Field = {
  id: string;
  type: string;
  value: string;
  submitted: boolean;
  properties: Properties;
}

export default defineComponent({
  name: "OtpConfirmationWidget",
  props: {
    widgetConfig: {
      type: Object as PropType<Field>,
      required: true,
    },
  },
  setup(props) {
    const otpLength = props.widgetConfig.properties.length;
    const otpDigits = ref<string[]>(Array(otpLength).fill(""));
    const showOtpError = ref(false);
    const otpInputs = ref<HTMLInputElement[]>([]);

    const validateOtp = () => {
      const otp = otpDigits.value.join("");
      showOtpError.value = otp.length !== otpLength || !/^\d+$/.test(otp);
      props.widgetConfig.submitted = !showOtpError.value;
      props.widgetConfig.value = otp;
    };

    const onInput = (index: number, event: Event) => {
      const input = event.target as HTMLInputElement;
      if (!/^\d$/.test(input.value)) {
        input.value = "";
      } else {
        otpDigits.value[index] = input.value;
        if (index < otpLength - 1 && input.value) {
          const nextInput = otpInputs.value[index + 1];
          if (nextInput) nextInput.focus();
        }
      }
      validateOtp();
    };

    const resendOtp = () => {
      otpApiService.resendOtp(localStorage.getItem("journeyId"));
      Swal.fire("OTP Resent", "OTP has been resent to your registered mobile number", "success");
    };

    watch(otpDigits, validateOtp);

    return {
      otpDigits,
      showOtpError,
      onInput,
      resendOtp,
      otpInputs,
    };
  },
});
</script>

<style scoped>
.otp-widget {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.otp-inputs {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.otp-digit-input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s;
}

.otp-digit-input:focus {
  border-color: #007bff;
}

.resend-container {
  margin-top: 20px;
  text-align: center;
}

button[disabled] {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>
