import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router'
import HomeView from '@/views/StartView.vue'
import JourneyView from '@/views/JourneyView.vue'
import CompletedView from "@/views/CompletedView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'start',
    component: HomeView
  },
  {
    path: '/journey',
    name: 'journey',
    component: JourneyView
  },
  {
    path: '/completed',
    name: 'completed',
    component: CompletedView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
