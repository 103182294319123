<template>
  <div class="label-widget">
    {{ widgetConfig.value }}
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {Field} from "@/service/server/model/response/Field";

export default defineComponent({
  name: "LabelWidget",
  props: {
    widgetConfig: {
      type: Object as PropType<Field>,
      required: true,
    },
  },
});
</script>

<style scoped>
.label-widget {
  font-size: 14px;
  color: #333;
}
</style>
