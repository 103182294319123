<template>
  <div class="widget-container">
    <label :for="widgetConfig.id">{{ widgetConfig.properties.label }}</label>
    <VueDatePicker
      :id="widgetConfig.id"
      v-model="selectedDate"
      @change="validateSelection"
      :format="widgetConfig.properties.format"
      :input-class="'datepicker-input'"
      :enable-time-picker="false"
    />
    <div v-if="errorMessage" class="widget-error-message">{{ errorMessage }}</div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType, ref, watch} from "vue";
import VueDatePicker from '@vuepic/vue-datepicker';

type Properties = {
  label: string;
  format: string;
};

type ValidationRule = {
  required?: boolean;
};

type Validation = {
  rules: ValidationRule[];
  message: string;
};

type Field = {
  id: string;
  type: string;
  value: string;
  properties: Properties;
  validation: Validation[];
  submitted: boolean;
};

export default defineComponent({
  name: "DateWidget",
  props: {
    widgetConfig: {
      type: Object as PropType<Field>,
      required: true,
    },
  },
  components: {
    VueDatePicker,
  },
  setup(props) {
    const selectedDate = ref<string>(props.widgetConfig.value || "");
    const errorMessage = ref("");

    const validateSelection = () => {
      errorMessage.value = "";
      for (const validation of props.widgetConfig.validation) {
        for (const rule of validation.rules) {
          if (rule.required && !selectedDate.value) {
            errorMessage.value = validation.message;
            break;
          }
        }
        if (errorMessage.value) break;
      }
      props.widgetConfig.submitted = !errorMessage.value;
      props.widgetConfig.value = selectedDate.value;
      console.log("Selection validated:", selectedDate.value);
    };

    watch(selectedDate, (newValue) => {
      console.log("Selected date changed:", newValue);
      validateSelection();
    });

    return {
      selectedDate,
      errorMessage,
      validateSelection,
    };
  },
});
</script>

<style scoped>

label {
  display: block;
  font-size: 16px;
  margin-bottom: 8px;
  color: #000;
}
</style>
