import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-08dbe6e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "widget-container" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id"]
const _hoisted_4 = {
  key: 0,
  class: "widget-error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.widgetConfig.id,
      class: "input-label"
    }, _toDisplayString(_ctx.widgetConfig.properties.label), 9, _hoisted_2),
    _withDirectives(_createElementVNode("input", {
      id: _ctx.widgetConfig.id,
      type: "text",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.validateInput && _ctx.validateInput(...args))),
      class: "text-input"
    }, null, 40, _hoisted_3), [
      [_vModelText, _ctx.inputValue]
    ]),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true)
  ]))
}