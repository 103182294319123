<template>
  <div class="widget-container">
    <div class="input-group">
      <label class="input-label">Email address</label>
      <input
        class="input-field"
        type="email"
        v-model="email"
        @input="validateEmail"
        placeholder="max@muster.at"
      />
    </div>
    <div v-if="showEmailError" class="widget-error-message">Invalid email address</div>

    <div v-if="widgetConfig.properties.repeat" class="input-group">
      <label class="input-label">Repeat email address</label>
      <input
        class="input-field"
        type="email"
        v-model="repeatEmail"
        @input="validateRepeatEmail"
        placeholder="max@muster.at"
      />
    </div>
    <div v-if="showRepeatEmailError" class="widget-error-message">Emails do not match</div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType, ref} from "vue";

type Properties = {
  regex: string;
  repeat: boolean;
}

type Field = {
  id: string;
  type: string;
  submitted: boolean;
  properties: Properties;
  value: string;
}

export default defineComponent({
  name: "EmailWidget",
  props: {
    widgetConfig: {
      type: Object as PropType<Field>,
      required: true,
    },
  },
  setup(props) {
    const email = ref(props.widgetConfig.value || "");
    const repeatEmail = ref("");
    const showEmailError = ref(false);
    const showRepeatEmailError = ref(false);

    const validateEmail = () => {
      const regex = new RegExp(props.widgetConfig.properties.regex);
      showEmailError.value = !regex.test(email.value);
      props.widgetConfig.submitted = !showEmailError.value && (!props.widgetConfig.properties.repeat || email.value === repeatEmail.value);
      props.widgetConfig.value = email.value;
    };

    const validateRepeatEmail = () => {
      showRepeatEmailError.value = email.value !== repeatEmail.value;
      props.widgetConfig.submitted = !showRepeatEmailError.value && !showEmailError.value;
    };

    return {
      email,
      repeatEmail,
      showEmailError,
      showRepeatEmailError,
      validateEmail,
      validateRepeatEmail,
    };
  },
});
</script>

<style scoped>

.input-group {
  margin-bottom: 15px;
}

.input-label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.input-field {
  width: calc(100% - 20px);
  padding: 8px;
  font-size: 14px;
  border-radius: 3px;
  border: 1px solid #ccc;
}
</style>
