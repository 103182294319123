<template>
  <div class="journey-container journey-container-padding">
    <!-- widgets will be rendered here based on journeyConfig -->
    <div id="journeyNavigation">
      <!-- Navigation-->
      <NavigationWidget :navigationConfig="new JourneyNavigation(journeyConfig?.navigation)"/>
    </div>

    <div id="journeyContent">
      <!-- Content -->
      <h1>{{ journeyConfig.screen?.title }}</h1>

      <!-- Content#main -->
      <div v-for="(row, rowIndex) in journeyConfig.screen?.main" :key="'main-' + rowIndex" class="content-main">
        <component
          v-for="field in row"
          :is="getContentComponent(field.type)"
          :key="field.id"
          :widget-config="field"
          @proceed="handleProceed"
          :is-disabled="isDisabled"
          @input="checkIfDisabled"
        ></component>
      </div>

      <div id="journeyFooter">
        <!-- Content#footer -->
        <div v-for="(row, rowIndex) in journeyConfig.screen?.footer" :key="'footer-' + rowIndex"
             class="content-footer">
          <hr/>
          <component
            v-for="field in row"
            :is="getContentComponent(field.type)"
            :key="field.id"
            :widget-config="field"
            @proceed="handleProceed"
            :is-disabled="isDisabled"
            @input="checkIfDisabled"
          ></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, watch} from "vue";
import {JourneyResponse} from "@/service/server/model/response/JourneyResponse";
import journeyApiService from "@/service/server/api/JourneyApiService";
import {JourneyRequest} from "@/service/server/model/request/JourneyRequest";
import {WidgetTypeService} from "@/service/journey/WidgetTypeService";
import {JourneyNavigation} from "@/service/server/model/response/JourneyNavigation";
import {Field} from "@/service/server/model/response/Field";
import router from "@/router";
import Swal from 'sweetalert2';
import {ValidationRuleService} from "@/service/journey/ValidationRuleService";
import NavigationWidget from "@/widgets/NavigationWidget.vue";

export default defineComponent({
  name: "JourneyViewManager",
  computed: {
    JourneyNavigation() {
      return JourneyNavigation;
    }
  },
  components: {
    NavigationWidget,
    ...WidgetTypeService.getWidgetComponents()
  },
  props: {
    journeyConfig: {
      type: Object as () => JourneyResponse,
      required: true
    }
  },
  setup(props) {
    const journeyConfig = ref(props.journeyConfig);
    console.log("Journey config", journeyConfig.value);
    const isDisabled = ref(true);

    const getContentComponent = (fieldType: string) => {
      return WidgetTypeService.getComponent(fieldType);
    };

    const getAllFields = () => {
      const mainFields = journeyConfig.value.screen?.main ?? [];
      const footerFields = journeyConfig.value.screen?.footer ?? [];
      const allFields: Field[] = [
        ...mainFields.flat(),
        ...footerFields.flat()
      ];
      return allFields;
    };

    const checkIfDisabled = () => {
      const allFields = getAllFields();

      if (!allFields || allFields.length === 0) {
        console.log("No fields found");
        isDisabled.value = true;
        return true;
      }

      for (const field of allFields) {
        if (!ValidationRuleService.isFieldValid(field)) {
          console.log("Field is not valid", field);
          isDisabled.value = true;
          return true;
        }
      }

      console.log("All fields are valid");
      isDisabled.value = false;
      return false;
    };

    const handleProceed = () => {
      const allFields = getAllFields();

      console.log("All fields", allFields);

      const data: { [key: string]: any } = allFields.reduce((acc, field) => {
        acc[field.id] = field.value;
        return acc;
      }, {} as { [key: string]: any });

      submitData(data);
    };

    const submitData = (data: { [key: string]: any }) => {
      const journeyId = localStorage.getItem("journeyId");
      console.log("Data to be submitted", data);
      if (journeyId) {
        journeyApiService
          .transitionJourney(journeyId, new JourneyRequest(data))
          .then((response) => {
            console.log(response);
            if (response.data.metadata.currentStep === 'COMPLETED') {
              localStorage.clear();
              router.push('completed');
            }
            console.log("Data submitted successfully", response);
            localStorage.setItem("journeyConfig", JSON.stringify(response.data));
            journeyConfig.value = new JourneyResponse(response.data);
            checkIfDisabled();
          })
          .catch((error) => {
            console.log(error);
            if (error.response.data?.code === 409) {
              // for each error.response.data.data its field and message is displayed
              for (const errorData of error.response.data.data.data) {
                Swal.fire({
                  title: 'Error',
                  text: `Validation error ${errorData.field ? ' for field ' + errorData.field : ''}: ${errorData.message}`,
                  icon: 'error',
                  confirmButtonText: 'OK'
                });
              }
            } else {
              console.error("Error submitting data", error);
              Swal.fire({
                title: 'Error',
                text: 'Error submitting data',
                icon: 'error',
                confirmButtonText: 'OK'
              });
            }
          });
      } else {
        console.error("Journey ID is not available");
        Swal.fire({
          title: 'Error',
          text: 'Submission failed: Journey ID is missing',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    };

    watch(() => journeyConfig.value, checkIfDisabled, {deep: true, immediate: true});

    return {
      journeyConfig,
      getContentComponent,
      handleProceed,
      isDisabled,
      checkIfDisabled
    };
  }
});
</script>
