<template>
  <div class="widget-container">
    <label :for="widgetConfig.id">{{ widgetConfig.properties.title }}</label>
    <div class="range-info">
      <span>{{ formatValue(selectedValue) }}</span>
    </div>
    <input
      type="range"
      :id="widgetConfig.id"
      :min="widgetConfig.properties.min"
      :max="widgetConfig.properties.max"
      :step="stepSize"
      v-model="selectedValue"
      @input="validateSelection"
    />
    <div class="range-labels">
      <span>{{ widgetConfig.properties.min }}</span>
      <span>More than {{ widgetConfig.properties.max }}</span>
    </div>
    <div v-if="errorMessage" class="widget-error-message">{{ errorMessage }}</div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType, ref, watch} from "vue";

type Properties = {
  title: string;
  min: number;
  max: number;
  steps: number;
  unit: string;
};

type ValidationRule = {
  required?: boolean;
};

type Validation = {
  rules: ValidationRule[];
  message: string;
};

type Field = {
  id: string;
  type: string;
  value: number;
  properties: Properties;
  validation: Validation[];
  submitted: boolean;
};

export default defineComponent({
  name: "RangeWidget",
  props: {
    widgetConfig: {
      type: Object as PropType<Field>,
      required: true,
    },
  },
  setup(props) {
    const selectedValue = ref<number>(props.widgetConfig.value || props.widgetConfig.properties.min);
    const errorMessage = ref("");

    const stepSize = ref<number>((props.widgetConfig.properties.max - props.widgetConfig.properties.min) / props.widgetConfig.properties.steps);

    const formatValue = (value: number) => {
      return `${props.widgetConfig.properties.unit} ${value}`;
    };

    const validateSelection = () => {
      errorMessage.value = "";
      for (const validation of props.widgetConfig.validation) {
        for (const rule of validation.rules) {
          if (rule.required && (!selectedValue.value)) {
            errorMessage.value = validation.message;
            break;
          }
        }
        if (errorMessage.value) break;
      }
      props.widgetConfig.submitted = !errorMessage.value;
      props.widgetConfig.value = selectedValue.value;
      console.log("Selection validated:", selectedValue.value);
    };

    watch(selectedValue, (newValue) => {
      console.log("Selected value changed:", newValue);
      validateSelection();
    });

    return {
      selectedValue,
      stepSize,
      errorMessage,
      validateSelection,
      formatValue
    };
  },
});
</script>

<style scoped>

label {
  display: block;
  font-size: 16px;
  margin-bottom: 8px;
  color: #000;
}

.range-info {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  margin-bottom: 8px;
  color: #000;
}

input[type="range"] {
  width: 100%;
}

.range-labels {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-top: 8px;
  color: #666;
}
</style>
