<template>
  <div class="startView">
    <h1>{{ welcome() }}</h1>
    <Multiselect v-model="selectedLanguage" :options="supportedLanguages" placeholder="Select language"/>
    <br>
    <button @click="startJourney" class="start-button">
      <i v-if="loading" class="pi pi-spin pi-cog" style="font-size: 2rem"></i>
      <span v-else>Start Journey</span>
    </button>
  </div>
</template>

<script lang="ts">
import {JourneyResponse} from "@/service/server/model/response/JourneyResponse";
import Multiselect from 'vue-multiselect'
import {Options, Vue} from "vue-class-component";
import {StartRequest} from "@/service/server/model/request/StartRequest";
import JourneyApiService from "@/service/server/api/JourneyApiService";
import router from "@/router";

@Options({
  name: 'InitialViewManager',
  props: {
    journeyConfig: JourneyResponse,
    msg: String
  },
  components: {
    Multiselect
  }
})
export default class InitialViewManager extends Vue {
  msg!: string;
  supportedLanguages: string[] = ['en', 'de'];
  // Default selected language
  selectedLanguage: string = 'en';
  loading: boolean = false;

  welcome(): string {
    return this.msg;
  }

  getLanguageName(code: string): string {
    const languages: { [key: string]: string } = {
      en: 'English',
      de: 'Deutsch'
    };
    return languages[code] || code;
  }

  async startJourney() {
    console.log('Journey started');
    const startData = new StartRequest(this.selectedLanguage);

    try {
      this.loading = true;

      // Commented Code is for checking if journey is already started, and if so, redirect to this step#
      // BUT since we want for Developers to be able to start a new journey each time, we will not check if journey is already started
      // TODO: Uncomment this code if you want to check if journey is already started
      //if (localStorage.getItem('journeyConfig')) {
      //  console.log('Journey already started');
      //  await router.push({name: 'journey'});
      //  return;
      //} else {
      const response = await JourneyApiService.startJourney(startData);
      const journeyResponse = new JourneyResponse(response.data);
      console.log(journeyResponse);
      localStorage.setItem('token', journeyResponse.token);
      console.log('Journey ID: ', journeyResponse.metadata.id);
      localStorage.setItem('journeyId', journeyResponse.metadata.id);
      localStorage.setItem('journeyConfig', JSON.stringify(journeyResponse));

      await new Promise(resolve => setTimeout(resolve, 1000));
      await router.push({name: 'journey'});
      //}
    } catch (error: any) {
      console.error("Code: " + error.code, "\nMessage: " + error.message);
    } finally {
      this.loading = false;
    }
  }
}
</script>
