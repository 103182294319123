<template>
  <div class="widget-container">
    <label :for="widgetConfig.id" class="toggle-label">
      <span class="toggle-text">{{ widgetConfig.properties.label }}</span>
      <VueToggles
        v-model="checked"
        :id="widgetConfig.id"
        :width="40"
        checkedBg="#b4d455"
        uncheckedBg="lightgrey"
        class="toggle-input"
        @click="onToggleChange"/>
    </label>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, watch} from 'vue';
import {VueToggles} from 'vue-toggles';

export default defineComponent({
  name: 'ToggleWidget',
  components: {
    VueToggles
  },
  props: {
    widgetConfig: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const checked = ref(props.widgetConfig.value);

    watch(() => props.widgetConfig.value, (newValue) => {
      checked.value = newValue;
    });

    const onToggleChange = () => {
      console.log('Toggle value changed to:', checked.value);
      props.widgetConfig.value = checked.value;
    };

    return {
      checked,
      onToggleChange
    };
  }
});
</script>

<style scoped>

.toggle-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  color: #333;
}

.toggle-text {
  margin-right: 8px;
}

.toggle-input {
  min-width: 40px;
}
</style>
