export class ValidationRuleService {
  public static isFieldValid(field: FieldDef): boolean {
    if (field.validation) {
      for (const validation of field.validation) {
        for (const rule of validation.rules) {
          if ((rule.required && !field.value)
            || (rule.value && field.value !== rule.value)
            || (rule.minLength && field.value.length < rule.minLength)
            || (rule.maxLength && field.value.length > rule.maxLength)
            || (rule.pattern && !new RegExp(rule.pattern).test(field.value))) {

            console.log("Field", field, " is invalid, rules: required: ", rule.required,
              ", value: ", rule.value,
              ", min Length: ", rule.minLength,
              ", max Length: ", rule.maxLength,
              ", rulePattern: ", rule.pattern);

            return false;
          }
        }
      }
    }
    return true;
  }
}
