<template>
  <div class="widget-container">
    <button @click="onProceed" class="proceed-button" :disabled="isDisabled">
      {{ widgetConfig.properties.label }}
    </button>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'ProceedButtonWidget',
  props: {
    widgetConfig: {
      type: Object,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onProceed() {
      this.widgetConfig.submitted = true;
      this.$emit('proceed');
    },
  },
});
</script>

<style scoped>

.proceed-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.proceed-button:hover {
  background-color: #0056b3;
}

.proceed-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
</style>
