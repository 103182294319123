import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0bd4cb08"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "widget-container" }
const _hoisted_2 = { class: "area-code-container" }
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = {
  key: 0,
  class: "widget-error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.widgetConfig.properties.showAreaCode)
        ? (_openBlock(), _createBlock(_component_Multiselect, {
            key: 0,
            modelValue: _ctx.areaCode,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.areaCode) = $event)),
            options: _ctx.areaCodes,
            class: "area-code-dropdown",
            placeholder: "+XX"
          }, null, 8, ["modelValue", "options"]))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.phoneNumber) = $event)),
        class: "phone-number-input",
        onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.validatePhoneNumber && _ctx.validatePhoneNumber(...args))),
        placeholder: _ctx.widgetConfig.properties.showAreaCode ? 'Phone Number' : 'Area Code + Phone Number'
      }, null, 40, _hoisted_3), [
        [_vModelText, _ctx.phoneNumber]
      ])
    ]),
    (_ctx.showPhoneError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, "Invalid phone number"))
      : _createCommentVNode("", true)
  ]))
}