import apiService from "@/service/server/EndpointService";
import {AxiosResponse} from "axios";
import {JourneyResponse} from "@/service/server/model/response/JourneyResponse";

class JourneyApiService {
  startUrl: string = 'journey/start';

  async startJourney(data: StartJourneyRequestDef): Promise<AxiosResponse<JourneyResponse>> {
    console.log('startJourney', data);
    console.log('apiService.getClientIdHeader()', apiService.getClientIdHeader());
    return apiService.postWithHeader(this.startUrl, data, apiService.getClientIdHeader());
  }

  async getJourney(id: string): Promise<AxiosResponse<JourneyResponse>> {
    return apiService.get(`journey/${id}`);
  }

  async transitionJourney(id: string, data: JourneyRequestDef): Promise<AxiosResponse<JourneyResponse>> {
    return apiService.post(`journey/${id}`, data);
  }

  async getJourneyContentsFile(id: any, fileId: string): Promise<AxiosResponse> {
    return apiService.getWithResponseType(`journey/${id}/files/${fileId}`, 'blob');
  }

  async getJourneyContentsCollections(id: any, collectionId: string): Promise<AxiosResponse> {
    return apiService.get(`journey/${id}/collections/${collectionId}`);
  }
}

export default new JourneyApiService();
