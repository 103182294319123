import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4b2da9d7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "widget-container" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "toggle-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueToggles = _resolveComponent("VueToggles")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.widgetConfig.id,
      class: "toggle-label"
    }, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.widgetConfig.properties.label), 1),
      _createVNode(_component_VueToggles, {
        modelValue: _ctx.checked,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checked) = $event)),
        id: _ctx.widgetConfig.id,
        width: 40,
        checkedBg: "#b4d455",
        uncheckedBg: "lightgrey",
        class: "toggle-input",
        onClick: _ctx.onToggleChange
      }, null, 8, ["modelValue", "id", "onClick"])
    ], 8, _hoisted_2)
  ]))
}