/**
 * StartRequest model to be sent to the server
 */
export class StartRequest implements StartJourneyRequestDef {
  language: string;

  constructor(language: string) {
    this.language = language;
  }
}
