<template>
  <div class="terms-and-conditions-widget">
    <div v-if="!showFileReader" class="terms-container">
      <div class="terms-label">
        {{ widgetConfig.properties.label }}
      </div>
      <button @click.prevent="openDocument" class="terms-link">
        <i class="pi pi-chevron-right"></i>
      </button>
    </div>
    <div v-else class="file-reader">
      <button class="back-button" @click="closeDocument">
        <i class="pi pi-chevron-left"></i>
      </button>
      <VuePdfEmbed v-if="fileURL" :source="fileURL" class="pdf-viewer"/>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import journeyApiService from "@/service/server/api/JourneyApiService";
import VuePdfEmbed from 'vue-pdf-embed';

type Source = {
  format: string;
  url: string;
}

type Properties = {
  label: string;
  type: string;
  source: Source;
}

type Widget = {
  id: string;
  type: string;
  submitted: boolean;
  properties: Properties;
}

@Options({
  name: "TermsAndConditionsWidget",
  props: {
    widgetConfig: {
      type: Object as () => Widget,
      required: true,
    },
  },
  components: {
    VuePdfEmbed
  }
})
export default class TermsAndConditionsWidget extends Vue {
  widgetConfig!: Widget;
  fileURL: string = "";
  showFileReader: boolean = false;

  openDocument() {
    console.log(
      "Opening document, id:",
      this.widgetConfig.id + " journeyId:",
      localStorage.getItem("journeyId")
    );
    journeyApiService
      .getJourneyContentsFile(localStorage.getItem("journeyId"), this.widgetConfig.id)
      .then((response) => {
        const blob = new Blob([response.data], {type: "application/pdf"});
        this.fileURL = URL.createObjectURL(blob);
        this.showFileReader = true;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  closeDocument() {
    this.showFileReader = false;
    if (this.fileURL) {
      URL.revokeObjectURL(this.fileURL);
    }
    this.fileURL = "";
  }
}
</script>

<style scoped>
.terms-and-conditions-widget {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
}

.terms-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.terms-label {
  font-weight: bold;
  font-size: 1.2rem;
  color: #333333;
}

.terms-link {
  cursor: pointer;
  color: #007bff;
  background-color: transparent;
  border: none;
  font-size: 1rem;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;
}

.terms-link:hover {
  color: #0056b3;
}

.file-reader {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  padding: 20px;
}

.back-button {
  background: #ffffff;
  color: #0056b3;
  align-self: flex-start;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
}

.pdf-viewer {
  width: 100%;
  height: 100%;
}
</style>
