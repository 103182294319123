<script lang="ts">

import {Options, Vue} from "vue-class-component";

@Options({
  name: 'LogoAtom'
})
export default class LogoAtom extends Vue {
}
</script>

<template>
  <img alt="Cloud Catalyst" src="@/assets/cloud-catalyst.png">
</template>
