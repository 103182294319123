<template>
  <div class="widget-container">
    <label :for="widgetConfig.id">{{ widgetConfig.properties.label }}</label>
    <Multiselect
      v-model="selectedValue"
      :options="options"
      label="name"
      track-by="id"
      placeholder="Select an item"
      class="dropdown-select"
      @input="validateSelection"
    />
    <div v-if="errorMessage" class="widget-error-message">{{ errorMessage }}</div>
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, PropType, ref, watch} from "vue";
import journeyApiService from "@/service/server/api/JourneyApiService";
import Multiselect from "vue-multiselect";

type Properties = {
  label: string;
  type: string;
  source: {
    url: string;
    key: string;
    value: string;
  };
}

type ValidationRule = {
  required?: boolean;
}

type Validation = {
  rules: ValidationRule[];
  message: string;
}

type Field = {
  id: string;
  type: string;
  value: string;
  properties: Properties;
  validation: Validation[];
  submitted: boolean;
}

export default defineComponent({
  name: "DropdownWidget",
  props: {
    widgetConfig: {
      type: Object as PropType<Field>,
      required: true,
    },
  },
  components: {
    Multiselect,
  },
  setup(props) {
    const selectedValue = ref<any>(null);
    const options = ref<any[]>([]);
    const errorMessage = ref("");

    const fetchOptions = async () => {
      try {
        const response = await journeyApiService.getJourneyContentsCollections(
          localStorage.getItem("journeyId"),
          props.widgetConfig.properties.source.url.lastIndexOf("/") > -1
            ? props.widgetConfig.properties.source.url.substring(props.widgetConfig.properties.source.url.lastIndexOf("/") + 1)
            : props.widgetConfig.properties.source.url);
        
        const name = props.widgetConfig.properties.source.value;
        const id = props.widgetConfig.properties.source.key;
        options.value = response.data.map((item: any) => ({id: item[id], name: item[name]}));
        console.log("Options fetched:", options.value);
      } catch (error) {
        console.error("Error loading options", error);
      }
    };

    const validateSelection = () => {
      errorMessage.value = "";
      for (const validation of props.widgetConfig.validation) {
        for (const rule of validation.rules) {
          if (rule.required && !selectedValue.value) {
            errorMessage.value = validation.message;
            break;
          }
        }
        if (errorMessage.value) break;
      }
      props.widgetConfig.submitted = !errorMessage.value;
      props.widgetConfig.value = selectedValue.value;
      console.log("Selection validated:", selectedValue.value);
    };

    watch(selectedValue, (newValue) => {
      console.log("Selected value changed:", newValue);
      validateSelection();
    });

    onMounted(async () => {
      await fetchOptions();
      validateSelection();
    });

    return {
      selectedValue,
      options,
      errorMessage,
      validateSelection,
    };
  },
});
</script>
