<template>
  <div class="journey-view">
    <div v-if="journeyConfig">
      <JourneyViewManager :journeyConfig="journeyConfig"/>
    </div>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component";
import {JourneyResponse} from "@/service/server/model/response/JourneyResponse";
import JourneyViewManager from "@/views/manager/JourneyViewManager.vue";

@Options({
  name: 'JourneyView',
  components: {
    JourneyViewManager
  }
})
export default class JourneyView extends Vue {
  journeyConfig: JourneyResponse | null = null;

  created() {
    const storedJourneyConfig = localStorage.getItem('journeyConfig');
    console.log('storedJourneyConfig', storedJourneyConfig);
    if (storedJourneyConfig) {
      this.journeyConfig = JSON.parse(storedJourneyConfig) as JourneyResponse;
      console.log('journeyConfig ', this.journeyConfig);
    }
  }
}
</script>
