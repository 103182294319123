<template>
  <div class="widget-container">
    <div v-for="option in options" :key="option[widgetConfig.properties.source.key]" class="radio-option">
      <input
        type="radio"
        :id="option[widgetConfig.properties.source.key]"
        :name="widgetConfig.id"
        :value="option[widgetConfig.properties.source.key]"
        v-model="selectedValue"
        @change="validateSelection"
      />
      <label :for="option[widgetConfig.properties.source.key]">
        <div class="key">{{ option[widgetConfig.properties.source.key] }}</div>
        <div class="description" v-if="option[widgetConfig.properties.source.description]">
          {{ option[widgetConfig.properties.source.description] }}
        </div>
      </label>
    </div>
    <div v-if="errorMessage" class="widget-error-message">{{ errorMessage }}</div>
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, PropType, ref, watch} from "vue";
import journeyApiService from "@/service/server/api/JourneyApiService";

type Properties = {
  multipleSelection: boolean;
  source: {
    data: any[];
    url: string;
    key: string;
    description: string;
  };
};

type ValidationRule = {
  required?: boolean;
};

type Validation = {
  rules: ValidationRule[];
  message: string;
};

type Field = {
  id: string;
  type: string;
  value: string;
  properties: Properties;
  validation: Validation[];
  submitted: boolean;
};

export default defineComponent({
  name: "RadioWidget",
  props: {
    widgetConfig: {
      type: Object as PropType<Field>,
      required: true,
    },
  },
  setup(props) {
    const selectedValue = ref<string | null>(props.widgetConfig.value || null);
    const options = ref<any[]>([]);
    const errorMessage = ref("");

    const fetchOptions = async () => {
      try {
        if (props.widgetConfig.properties.source.data) {
          console.log("Options fetched:", props.widgetConfig.properties.source.data);
          options.value = props.widgetConfig.properties.source.data;
          return;
        } else {
          const response = await journeyApiService.getJourneyContentsCollections(
            localStorage.getItem("journeyId"),
            props.widgetConfig.properties.source.url.lastIndexOf("/") > -1
              ? props.widgetConfig.properties.source.url.substring(props.widgetConfig.properties.source.url.lastIndexOf("/") + 1)
              : props.widgetConfig.properties.source.url
          );
          options.value = response.data;
          console.log("Options fetched:", options.value);
        }
      } catch (error) {
        console.error("Error loading options", error);
      }
    };

    const validateSelection = () => {
      errorMessage.value = "";
      for (const validation of props.widgetConfig.validation) {
        for (const rule of validation.rules) {
          if (rule.required && !selectedValue.value) {
            errorMessage.value = validation.message;
            break;
          }
        }
        if (errorMessage.value) break;
      }
      props.widgetConfig.submitted = !errorMessage.value;

      if (typeof selectedValue.value === "string") {
        props.widgetConfig.value = selectedValue.value;
      }

      console.log("Selection validated:", selectedValue.value);
    };

    watch(selectedValue, (newValue) => {
      console.log("Selected value changed:", newValue);
      validateSelection();
    });

    onMounted(async () => {
      await fetchOptions();
      if (props.widgetConfig.value) {
        selectedValue.value = props.widgetConfig.value;
      }
      validateSelection();
    });

    return {
      selectedValue,
      options,
      errorMessage,
      validateSelection,
    };
  },
});
</script>

<style scoped>

.radio-option {
  margin-bottom: 15px;
  border-bottom: 1px solid #e0e0e0;
}

.radio-option:last-child {
  border-bottom: none;
}

input[type="radio"] {
  margin-right: 10px;
  margin-top: 5px;
}

label {
  display: flex;
  flex-direction: column;
}

.key {
  font-weight: bold;
}

.description {
  font-size: 12px;
  color: #666;
}

</style>
