import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7c967624"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "widget-container" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "range-info" }
const _hoisted_4 = ["id", "min", "max", "step"]
const _hoisted_5 = { class: "range-labels" }
const _hoisted_6 = {
  key: 0,
  class: "widget-error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.widgetConfig.id
    }, _toDisplayString(_ctx.widgetConfig.properties.title), 9, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", null, _toDisplayString(_ctx.formatValue(_ctx.selectedValue)), 1)
    ]),
    _withDirectives(_createElementVNode("input", {
      type: "range",
      id: _ctx.widgetConfig.id,
      min: _ctx.widgetConfig.properties.min,
      max: _ctx.widgetConfig.properties.max,
      step: _ctx.stepSize,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedValue) = $event)),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.validateSelection && _ctx.validateSelection(...args)))
    }, null, 40, _hoisted_4), [
      [_vModelText, _ctx.selectedValue]
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("span", null, _toDisplayString(_ctx.widgetConfig.properties.min), 1),
      _createElementVNode("span", null, "More than " + _toDisplayString(_ctx.widgetConfig.properties.max), 1)
    ]),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true)
  ]))
}