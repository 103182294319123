import axios, {AxiosHeaders, AxiosResponse, ResponseType} from 'axios';

const apiVersion: string = 'v1';

// Define the base URL from environment variables or default value is used ( localhost )
const BASE_URL: string = 'https://kyc-orchestrator.cloud-catalyst.io/' + apiVersion;

//const BASE_URL: string = process.env.VUE_APP_API_URL || 'http://localhost:8091/' + apiVersion;


export interface ApiService {
  get(endpoint: string): Promise<AxiosResponse>;

  getWithResponseType(endpoint: string, responseType: ResponseType): Promise<AxiosResponse>;

  post(endpoint: string, data: any): Promise<AxiosResponse>;

  postWithHeader(endpoint: string, data: any, header: AxiosHeaders): Promise<AxiosResponse>;

  put(endpoint: string, data: any): Promise<AxiosResponse>;

  delete(endpoint: string): Promise<AxiosResponse>;

  getAuthHeader(): AxiosHeaders;

  getClientIdHeader(): AxiosHeaders;
}

/**
 * Service for making API calls <br>
 *
 * Usage: <br>
 * <ul>
 *     <li>apiService.get('journey/some-id')</li>
 *     <li>apiService.post('journey/start', data)</li>
 *     <li> ... </li>
 * </ul>
 * @implements ApiService
 */
const apiService: ApiService = {
  async get(endpoint: string) {
    return axios.get(`${BASE_URL}/${endpoint}`, {
      headers: this.getAuthHeader()
    });
  },

  async getWithResponseType(endpoint: string, responseType: ResponseType) {
    return axios.get(`${BASE_URL}/${endpoint}`, {
      headers: this.getAuthHeader(),
      responseType: responseType
    });
  },

  async post(endpoint: string, data: any) {
    return this.postWithHeader(endpoint, data, this.getAuthHeader());
  },

  async postWithHeader(endpoint: string, data: any, header: AxiosHeaders) {
    return axios.post(`${BASE_URL}/${endpoint}`, data, {
      headers: header
    });
  },

  async put(endpoint: string, data: any) {
    return axios.put(`${BASE_URL}/${endpoint}`, data, {
      headers: this.getAuthHeader()
    });
  },

  async delete(endpoint: string) {
    return axios.delete(`${BASE_URL}/${endpoint}`, {
      headers: this.getAuthHeader()
    });
  },

  getAuthHeader(): AxiosHeaders {
    // TODO: Authorization token should be set here, clientId is used only to start the
    //  project, implement store or worker
    const token: string | null = localStorage.getItem('token');
    return new AxiosHeaders({
      'Authorization': `Bearer ${token}`,
    });
  },

  getClientIdHeader(): AxiosHeaders {
    return new AxiosHeaders({
      //TODO: Concept for client id
      'X-Client-ID': 'aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa'
    });
  }
};

export default apiService;
