import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7f532e9a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "widget-container" }
const _hoisted_2 = ["id", "name", "value"]
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "key" }
const _hoisted_5 = {
  key: 0,
  class: "description"
}
const _hoisted_6 = {
  key: 0,
  class: "widget-error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
      return (_openBlock(), _createElementBlock("div", {
        key: option[_ctx.widgetConfig.properties.source.key],
        class: "radio-option"
      }, [
        _withDirectives(_createElementVNode("input", {
          type: "radio",
          id: option[_ctx.widgetConfig.properties.source.key],
          name: _ctx.widgetConfig.id,
          value: option[_ctx.widgetConfig.properties.source.key],
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedValue) = $event)),
          onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.validateSelection && _ctx.validateSelection(...args)))
        }, null, 40, _hoisted_2), [
          [_vModelRadio, _ctx.selectedValue]
        ]),
        _createElementVNode("label", {
          for: option[_ctx.widgetConfig.properties.source.key]
        }, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(option[_ctx.widgetConfig.properties.source.key]), 1),
          (option[_ctx.widgetConfig.properties.source.description])
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(option[_ctx.widgetConfig.properties.source.description]), 1))
            : _createCommentVNode("", true)
        ], 8, _hoisted_3)
      ]))
    }), 128)),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true)
  ]))
}