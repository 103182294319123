<script lang="ts">
import {Vue} from 'vue-class-component';

export default class ChipScannerWidget extends Vue implements Widget {

  render(): any {
  }
}
</script>

<template>

</template>
