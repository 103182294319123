import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7d578977"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "widget-container" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "widget-error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.widgetConfig.id
    }, _toDisplayString(_ctx.widgetConfig.properties.label), 9, _hoisted_2),
    _createVNode(_component_VueDatePicker, {
      id: _ctx.widgetConfig.id,
      modelValue: _ctx.selectedDate,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedDate) = $event)),
      onChange: _ctx.validateSelection,
      format: _ctx.widgetConfig.properties.format,
      "input-class": 'datepicker-input',
      "enable-time-picker": false
    }, null, 8, ["id", "modelValue", "onChange", "format"]),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true)
  ]))
}