import CheckboxWidget from '@/widgets/not_implemented/CheckboxWidget.vue';
import ChipScannerWidget from '@/widgets/not_implemented/ChipScannerWidget.vue';
import DocumentScannerWidget from '@/widgets/not_implemented/DocumentScannerWidget.vue';
import DropdownWidget from '@/widgets/DropdownWidget.vue';
import EmailWidget from '@/widgets/EmailWidget.vue';
import HtmlContentWidget from '@/widgets/not_implemented/HtmlContentWidget.vue';
import PhoneNumberWidget from '@/widgets/PhoneNumberWidget.vue';
import ProceedButtonWidget from '@/widgets/ProceedButtonWidget.vue';
import RangeWidget from '@/widgets/RangeWidget.vue';
import TermsAndConditionsWidget from '@/widgets/TermsAndConditionsWidget.vue';
import TextWidget from '@/widgets/TextWidget.vue';
import TimelineWidget from '@/widgets/not_implemented/TimelineWidget.vue';
import ToggleWidget from '@/widgets/ToggleWidget.vue';
import OtpConfirmationWidget from '@/widgets/OtpConfirmationWidget.vue';
import LabelWidget from "@/widgets/LabelWidget.vue";
import NavigationWidget from "@/widgets/NavigationWidget.vue";
import {defineComponent} from "vue";
import RadioWidget from "@/widgets/RadioWidget.vue";
import DateWidget from "@/widgets/DateWidget.vue";

export class WidgetTypeService {

  private constructor() {
    // Utility class
  }

  private static widgetComponentMap: Record<string, ReturnType<typeof defineComponent>> = {
    checkbox: CheckboxWidget,
    chipScanner: ChipScannerWidget,
    documentScanner: DocumentScannerWidget,
    dropdown: DropdownWidget,
    email: EmailWidget,
    htmlContent: HtmlContentWidget,
    phoneNumber: PhoneNumberWidget,
    proceedButton: ProceedButtonWidget,
    range: RangeWidget,
    termsAndConditions: TermsAndConditionsWidget,
    text: TextWidget,
    timeline: TimelineWidget,
    toggle: ToggleWidget,
    otpConfirmation: OtpConfirmationWidget,
    label: LabelWidget,
    navigation: NavigationWidget,
    radio: RadioWidget,
    date: DateWidget
  };

  /**
   * Return Vue Component based on widget type
   * @param widgetType
   * @returns VueComponent
   */
  static getComponent(widgetType: string) {
    const component = this.widgetComponentMap[widgetType];
    if (!component) {
      throw new Error(`Unknown widget type: ${widgetType}`);
    }
    return component;
  }

  static getWidgetComponents() {
    return {...this.widgetComponentMap};
  }
}
