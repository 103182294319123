export class JourneyNavigation implements JourneyNavigationDef {
  title: string;
  allowBack: boolean;
  allowHelp: boolean;

  constructor(navigation: JourneyNavigationDef) {
    this.title = navigation?.title;
    this.allowBack = navigation?.allowBack;
    this.allowHelp = navigation?.allowHelp;
  }
}
